var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "b-card-forms" },
    [
      _c(
        "b-row",
        { staticClass: "vh-100", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "12", sm: "9", lg: "5" } },
            [
              _c(
                "b-card",
                { staticClass: "text-center error" },
                [
                  _c("img", {
                    staticClass: "webmag-logo-big",
                    attrs: {
                      src: require("@/assets/logo-webmag-big.png"),
                      alt: "webmag logo"
                    }
                  }),
                  _c("h3", { staticClass: "mb-4" }, [
                    _vm._v(_vm._s(_vm.$t("notInAllowedRoles.title")))
                  ]),
                  _c("b-alert", {
                    attrs: { show: "", variant: "danger" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("notInAllowedRoles.error"))
                    }
                  }),
                  _c(
                    "router-link",
                    {
                      staticClass: "mt-2 mb-5 btn py-2 px-4 btn-success",
                      attrs: { to: "/webmag-logout" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.logout")) + " ")]
                  ),
                  !_vm.onlyOneGroupForUserExists && _vm.userIdExists
                    ? _c(
                        "div",
                        [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("notInAllowedRoles.changeGroup"))
                            )
                          ]),
                          _c("header-group-dropdown", {
                            attrs: {
                              "is-sys-admin": false,
                              "user-id": _vm.userId,
                              "group-id": null
                            },
                            on: {
                              "only-one-group-exists": function($event) {
                                _vm.onlyOneGroupForUserExists = true
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("p", {
                    staticClass: "mt-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("notInAllowedRoles.desc"))
                    }
                  }),
                  _vm.codeBlock
                    ? _c("div", { staticClass: "mt-3" }, [
                        _c("hr"),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-block text-left mt-2 error-msg-block"
                          },
                          [
                            _c("h4", [_vm._v("Error Message:")]),
                            _vm.debugData
                              ? _c("div", {
                                  staticClass: "mb-2",
                                  domProps: { innerHTML: _vm._s(_vm.debugData) }
                                })
                              : _vm._e(),
                            _c("div", {
                              domProps: { innerHTML: _vm._s(_vm.codeBlock) }
                            })
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }